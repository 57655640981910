import {put, takeLatest} from "redux-saga/effects";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../router/RouterHelpers";
import {logout} from "../../crud/account.crud";
export const actionTypes = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAIL: "LOGOUT_FAIL"
};

const initialAuthState = {
  user: undefined,
  authTokenOrganiser: undefined
};

export const reducer = persistReducer(
    { storage, key: "pc-web-organiser-auth", whitelist: ["user", "authTokenOrganiser"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.LOGIN_SUCCESS: {
          const { token, user } = action.payload;
          localStorage.setItem('authTokenPCOrganiser', token);
          return { authTokenOrganiser: token, user: user };
        }

        case actionTypes.LOGOUT: {
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        case actionTypes.LOGOUT_SUCCESS: {
          localStorage.removeItem('authTokenPCOrganiser');
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        default:
          return state;
      }
    }
);

export const actions = {
  login: (data) => ({ type: actionTypes.LOGIN_SUCCESS, payload: data }),
  logout: () => ({ type: actionTypes.LOGOUT })
};


export function * saga() {
  yield takeLatest(actionTypes.LOGOUT, function * logoutProfile() {
      const {data} = yield logout();
      yield put({type: actionTypes.LOGOUT_SUCCESS, payload: {
              data: data.data
          }});
  });
}
