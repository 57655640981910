import React from "react";
import { Formik } from "formik";
import { Form, Button } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import { updateBank } from "../../../crud/account.crud";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
const BankDetails = (props) => {
  const { user, intl, classes } = props;
  return (
    <div
      className="tab-pane active"
      id="profile_change_password"
      role="tabpanel"
    >
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Bank Details
              <small>add or update your bank account information</small>
            </h3>
          </div>
        </div>
        <Formik
          initialValues={
            user.bankingDetails
              ? user.bankingDetails
              : {
                  bankName: "",
                  accountHolderName: "",
                  accountNumber: "",
                  ifscCode: "",
                  branchName: "",
                  gstNumber: "",
                }
          }
          validate={(values) => {
            const errors = {};
            if (!values.bankName) {
              errors.bankName = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }
            if (!values.accountHolderName) {
              errors.accountHolderName = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }
            if (!values.accountNumber) {
              errors.accountNumber = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }
            if (!values.ifscCode) {
              errors.ifscCode = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }
            if (!values.branchName) {
              errors.branchName = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }
            if (!values.gstNumber) {
              errors.gstNumber = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, resetForm, setSubmitting }) => {
            // console.log(values);
            updateBank(values)
              .then(() => {
                // resetForm();
                toast.success(
                  intl.formatMessage({ id: "AUTH.BANK_DETAILS.SUCCESS" }),
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    style: {
                      fontSize: "14px",
                      fontWeight: "bold",
                      paddingLeft: "12px",
                    },
                  }
                );
              })
              .catch((error) => {
                setSubmitting(false);
                if (error.response) {
                  setStatus(
                    intl.formatMessage({
                      id: ApiErrorMessage[error.response.data.errorCode],
                    })
                  );
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log("Error", error.message);
                }
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <Form
              className="kt-form kt-form--label-right"
              onSubmit={handleSubmit}
            >
              <div className="kt-portlet__body">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    <Form.Group controlId="bankDetails.bankName">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        name="bankName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bankName}
                        isInvalid={
                          !!Boolean(touched.bankName && errors.bankName)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.bankName && errors.bankName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="bankDetails.accountHolderName">
                      <Form.Label>Account Holder Name</Form.Label>
                      <Form.Control
                        name="accountHolderName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.accountHolderName}
                        isInvalid={
                          !!Boolean(
                            touched.accountHolderName &&
                              errors.accountHolderName
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.accountHolderName && errors.accountHolderName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="bankDetails.accountNumber">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        name="accountNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.accountNumber}
                        isInvalid={
                          !!Boolean(
                            touched.accountNumber && errors.accountNumber
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.accountNumber && errors.accountNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="bankDetails.ifscCode">
                      <Form.Label>IFSC Code</Form.Label>
                      <Form.Control
                        name="ifscCode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ifscCode}
                        isInvalid={
                          !!Boolean(touched.ifscCode && errors.ifscCode)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.ifscCode && errors.ifscCode}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="bankDetails.branchName">
                      <Form.Label>Branch Name</Form.Label>
                      <Form.Control
                        name="branchName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.branchName}
                        isInvalid={
                          !!Boolean(touched.branchName && errors.branchName)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.branchName && errors.branchName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="bankDetails.gstNumber">
                      <Form.Label>GST Code</Form.Label>
                      <Form.Control
                        name="gstNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.gstNumber}
                        isInvalid={
                          !!Boolean(touched.gstNumber && errors.gstNumber)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.gstNumber && errors.gstNumber}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {status && (
                      <div
                        role="alert"
                        style={{
                          margin: "0 16px",
                        }}
                        className="alert alert-danger"
                      >
                        <div className="alert-text">{status}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="kt-portlet__foot">
                <div className="kt-form__actions">
                  <div className="row">
                    <div className="col-lg-3 col-xl-3"></div>
                    <div className="col-lg-9 col-xl-9">
                      <Button
                        className="btn btn-success"
                        variant="primary"
                        style={{
                          position: "relative",
                          margin: "0 16px",
                        }}
                        onClick={() => handleSubmit()}
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                        Save Changes
                      </Button>
                      <Button
                        onClick={resetForm}
                        type="reset"
                        className="btn btn-secondary"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default injectIntl(
  withStyles(
    (theme) => ({
      ...styles(theme),
    }),
    { withTheme: true }
  )(BankDetails)
);
