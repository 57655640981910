import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import {toast} from 'react-toastify';
import Pagination from '@material-ui/lab/Pagination';
import {connect} from "react-redux";
import {listServiceRequest, acceptServiceRequest, rejectServiceRequest} from '../../../crud/service-request.crud';
import TimeSlotOptions from '../../../constants/time-slot-options';
import DaysOfWeek from '../../../constants/days-of-week';
const ServiceRequestList = (props) => {

    const [tableData,
        setTableData] = useState({data: [], skip: 0, limit: 5, total: 0, totalPage: 0});

    const [page,
        setPage] = useState(1);

    const getDays = (daysOfWeekData) => {
        return daysOfWeekData.map((day, i) => {
            return daysOfWeekData[i + 1]
                ? `${DaysOfWeek[day - 1]}, `
                : `${DaysOfWeek[day - 1]}`;
        });
    }

    useEffect(() => {
        reloadList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.data.serviceRequest.listData) {
            let listObject = {
                data: [...props.data.serviceRequest.listData.data.data.data],
                skip: tableData.skip,
                limit: tableData.limit,
                total: props.data.serviceRequest.listData.data.data.total,
                totalPage: Number((props.data.serviceRequest.listData.data.data.total / tableData.limit).toFixed())
            };
            setTableData({
                ...tableData,
                ...listObject
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.serviceRequest.listData]);

    const reloadList = () => {
        listServiceRequest({skip: tableData.skip, limit: tableData.limit}).then((docs) => {
            // console.log(docs);
            setTableData({
                data: [...docs.data.data.data],
                skip: tableData.skip,
                limit: tableData.limit,
                total: 0,
                totalPage: Number((docs.data.data.total / tableData.limit).toFixed())
            })
        });
    }

    const handleChange = (event, value) => {
        // console.log("value", value);
        setPage(value);

        listServiceRequest({
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit
        }).then((docs) => {
            // console.log(docs);
            let listObject = {
                data: [...docs.data.data.data],
                skip: Number((value - 1) * tableData.limit),
                limit: tableData.limit,
                total: docs.data.data.total,
                totalPage: Number((docs.data.data.total / tableData.limit).toFixed())
            };
            //   console.log("listObject", listObject);
            setTableData({
                ...tableData,
                ...listObject
            });
        });
    };

    return (
        <React.Fragment>
            {tableData.data.length
                ? (
                    <React.Fragment>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>SL No</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Vendor Name</th>
                                    <th>Available Slots</th>
                                    <th>Days Of Week</th>
                                    <th>Address</th>
                                    <th>Fees</th>

                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData
                                    .data
                                    .map((eachData, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{eachData
                                                        ._id
                                                        .substr(0, 4)}</td>
                                                <td>{eachData.title}</td>
                                                <td>{eachData.description}</td>
                                                <td>{eachData.vendorRef.personalInfo.name}</td>
                                                <td>{eachData
                                                        .availableSlots
                                                        .map((ts, i) => {
                                                            const label = TimeSlotOptions.filter((tso) => tso.value === ts)[0].label;
                                                            return eachData.availableSlots[i + 1]
                                                                ? `${label}, `
                                                                : `${label}`;
                                                        })}</td>
                                                <td>{getDays(eachData.daysOfWeek)}</td>
                                                <td>{eachData.address}</td>
                                                <td>{eachData.fees}</td>
                                                <td>{eachData.status === 1
                                                        ? 'Pending'
                                                        : (eachData.status === 2
                                                            ? 'Accepted'
                                                            : 'Rejected')}</td>
                                                <td>
                                                    {eachData.status === 1
                                                        ? (
                                                            <React.Fragment>
                                                                <p
                                                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                                                    title="Accept"
                                                                    onClick={() => {
                                                                    acceptServiceRequest(eachData._id).then(() => {
                                                                        toast.success(`You have accepted ${eachData.title} service request!`, {
                                                                            position: "top-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined
                                                                        });
                                                                        reloadList();
                                                                    });
                                                                }}
                                                                    style={{
                                                                    margin: "0"
                                                                }}>
                                                                    <i className="la la-check-circle"></i>
                                                                </p>

                                                                <p
                                                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                                                    title="Reject"
                                                                    style={{
                                                                    margin: "0"
                                                                }}
                                                                    onClick={() => {
                                                                    rejectServiceRequest(eachData._id).then(() => {
                                                                        reloadList();
                                                                    });
                                                                }}>
                                                                    <i className="la la-times-circle kt-font-danger"></i>
                                                                </p>
                                                            </React.Fragment>
                                                        )
                                                        : null}

                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </Table><Pagination
                            count={tableData.totalPage}
                            color="primary"
                            page={page}
                            onChange={handleChange}/>
                    </React.Fragment>
                )
                : (
                    <p>No Data Available</p>
                )}

        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {data: state};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestList);
